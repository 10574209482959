import axios from 'axios';
const API_URL = process.env.VUE_APP_BASE_API_URL;

class MediaService {
    getMedia(text) {
        return axios.get(`${API_URL}Files?search=${text}`);
    }
    get(id) {
        return axios.get(`${API_URL}Files/${id}`);
    }

    addMedia(data) {
        return axios.post(`${API_URL}Files`, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
    }

}

export default new MediaService();
