<template>
  <div class="media-container">
    <div class="media-header">
      <div class="form-group">
        <input
          type="text"
          placeholder="Szukaj pliku"
          v-model="search"
          id="search"
          class="form-control"
        />
      </div>
      <button @click="handleAddFilesClick" class="upload-button">Dodaj pliki</button>
      <input
        type="file"
        ref="fileInput"
        multiple
        @change="handleFileSelect"
        style="display: none"
      />
    </div>
    <div
      class="media-list"
      :class="{ 'drag-over': isDragOver }"
      @dragover.prevent="handleDragOver"
      @dragleave="handleDragLeave"
      @drop="handleDrop"
    >
      <MediaItem
        v-for="file in files"
        :key="file.id"
        @click="handleItemClick(file)"
        :file="file"
        :showDownload="showDownload"
      >
      </MediaItem>
    </div>
  </div>
</template>

<script>
import MediaService from "../../services/MediaService";
import MediaItem from "./MediaItem.vue";

export default {
  name: "MediaList",
  components: {
    MediaItem,
  },
  props: {
    showDownload: Boolean,
  },
  data() {
    return {
      isDragOver: false,
      files: [],
      search: "",
      debounceTimeout: null,
    };
  },
  beforeMount() {
    this.getMedia();
  },
  methods: {
    async downloadFile(url, name) {
      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error("Network response was not ok.");
        const blob = await response.blob();
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    },
    handleDrop(event) {
      event.preventDefault();
      this.isDragOver = false;
      const files = event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          const newFile = {
            id: Date.now() + i,
            name: file.name,
            url: e.target.result,
          };
          this.addFile(newFile);
        };
        reader.readAsDataURL(file);
      }
    },
    handleDragOver(event) {
      event.preventDefault();
      this.isDragOver = true;
    },
    handleDragLeave() {
      this.isDragOver = false;
    },
    handleAddFilesClick() {
      this.$emit("add-files-clicked");
      this.$refs.fileInput.click();
    },
    handleFileSelect(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          const newFile = {
            id: Date.now() + i,
            name: file.name,
            url: e.target.result,
          };
          this.addFile(newFile);
        };
        reader.readAsDataURL(file);
      }
    },
    handleItemClick(file) {
      this.$emit("file-clicked", file);
    },
    addFile(newFile) {
      const base64Data = newFile.url.split(",")[1]; // assuming `this.file` is a base64 string
      const contentType = newFile.url.split(",")[0].split(":")[1].split(";")[0];
      const blob = this.base64ToBlob(base64Data, contentType);

      const formData = new FormData();
      formData.append("file", blob, newFile.name); //trzy linie dodane bo nie wpisywal plikow
      MediaService.addMedia(formData).then(() => {
        this.getMedia();
      });
    },
    base64ToBlob(base64, contentType) {
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: contentType });
    },
    debounceSave() {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        this.getMedia();
      }, 500);
    },
    getMedia() {
      MediaService.getMedia(this.search).then((x) => {
        this.files = x.data;
      });
    },
  },
  watch: {
    search() {
      this.debounceSave();
    },
  },
};
</script>

<style scoped>
.media-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.media-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.upload-button {
  padding: 10px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.upload-button:hover {
  background-color: #0056b3;
}

.media-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  border: 2px dashed #ccc;
  padding: 10px;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.media-list.drag-over {
  border-color: #007bff;
}

.media-content {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.media-content img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
}

.media-icon {
  font-size: 50px;
}

.media-name {
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
}

.download-button {
  display: inline-block;
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.download-button:hover {
  background-color: #0056b3;
}

.form-group {
  margin-bottom: 15px;
  display: flex;
}

.form-group label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: bold;
}
.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.12);
  margin-right: 20px;
}
</style>
