<template>
  <div class="media-item">
    <div class="media-content">
      <template v-if="isImage(file.filePathR)">
        <img :src="file.filePathR" :alt="file.fileName" />
      </template>
      <template v-else-if="isVideo(file.filePathR)">
        <font-awesome-icon icon="file-video" class="media-icon" />
      </template>
      <template v-else-if="isAudio(file.filePathR)">
        <font-awesome-icon icon="file-audio" class="media-icon" />
      </template>
      <template v-else>
        <font-awesome-icon :icon="getFileIcon(file.filePathR)" class="media-icon" />
      </template>
    </div>
    <div class="media-name">{{ file.fileName }}</div>
    <a
      v-if="showDownload"
      :download="file.fileName"
      :href="file.filePathR"
      class="download-button"
    >
      Pobierz
    </a>
  </div>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

export default {
  components: {
    FontAwesomeIcon,
  },
  props: {
    file: Object,
    showDownload: Boolean,
  },
  methods: {
    isImage(url) {
      return /\.(jpeg|jpg|gif|png|svg)$/i.test(url);
    },
    isVideo(url) {
      return /\.(mp4|webm|ogg)$/i.test(url);
    },
    isAudio(url) {
      return /\.(mp3|wav|ogg)$/i.test(url);
    },
    getFileIcon(url) {
      if (this.isImage(url)) return "file-image";
      if (this.isVideo(url)) return "file-video";
      if (this.isAudio(url)) return "file-audio";
      if (/\.(pdf)$/i.test(url)) return "file-pdf";
      if (/\.(doc|docx)$/i.test(url)) return "file-word";
      if (/\.(xls|xlsx)$/i.test(url)) return "file-excel";
      if (/\.(ppt|pptx)$/i.test(url)) return "file-powerpoint";
      return "file-alt";
    },
  },
};
</script>
<style>
.media-item {
  margin: 10px;
  text-align: center;
  width: 150px;
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.media-content {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.media-content img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
}

.media-icon {
  font-size: 50px;
}

.media-name {
  font-size: 14px;
  margin-top: 5px;
  text-align: center;
}

.download-button {
  display: inline-block;
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.download-button:hover {
  background-color: #0056b3;
}
</style>
